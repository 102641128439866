const colorTypes = [
    //0 to 3
    'black', 'brightred', 'brown', 'electricblue',
    //4 to 7 
    'grey', 'oranges', 'pink', 'tortoise', 
    //8 to 9
    'vividgreen', 'yellow'
]
const glassesTypes = [ 
    //0 to 3
    'aviator','browline','cateye','geometric',
    //4 to 7
    'oval','oversized','rectangle',   'round', 
    //8
    'square'
]

const hairColors=['blonde', 'bruntte','red','gray','auburn','black']
const hairStyles=['short','long','wavy','straight','updo','bangs','pixiecut']
const frameRecommendationOnHS=[
    [3],[4,7],[7],[3],[2],[1,2,4,7],[2,4]
]
const ColorRecommendationsOnContrastHC=[
    [0,2],[4],[3],[6,8,3],[3],[1,3,5,6,8,9]
]
const ColorRecommendationsOnComplimentHC=[
    [7,9,3,4],[2,8],[8,2,1,9],[0,4],[8,2],[2,7]
]

export const HairColorStyle=(color,style)=>{

    const numberStyle=hairStyles.findIndex(x =>x===style)
    const numberColor=hairColors.findIndex(x =>x===color)
    
    const frameNumber=frameRecommendationOnHS[numberStyle]
    const complimentColors=ColorRecommendationsOnComplimentHC[numberColor]
    const contrastColors=ColorRecommendationsOnContrastHC[numberColor]
    
    var frames=[];
    var compliment=[];
    var contrast=[];

    
    frameNumber.forEach(n => {
        frames.push(glassesTypes[n])
        });
    
    complimentColors.forEach(n => {
        compliment.push(colorTypes[n])
        });
    contrastColors.forEach(n => {
        contrast.push(colorTypes[n])
        });
    
    
        const recommendation = {
          frame: frames,
          compliment,
        contrast
    }
    
    return recommendation
}



    