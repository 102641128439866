import React, { useState } from "react";
import OrderContext from "./ordersContext";

const OrderState = (props) => {
  
  const HOST = process.env.REACT_APP_BACKEND_URL;

  //get order
  const getOrder = async (page,limit) => {
    
    const url = `${HOST}/api/orders/getallorders?page=${page}&limit=${limit}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      },

    });
    const responseJSON = await response.json();
    setOrders(orders.concat(responseJSON.orders))
    setTotalPage(responseJSON.totalPages)
    
  }


  //add order
  const addOrder = async (image, faceShape,pupilDistance ,gender,age,faceColor,eyeColor,hairColor,hairStyle,personality,selectedFrame,colorFrame) => {

    const url = `${HOST}/api/orders/saveorder`
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token":localStorage.getItem('token')
      },

      body: JSON.stringify({ image, faceShape,pupilDistance, gender,age,faceColor,eyeColor,hairColor,hairStyle,personality,selectedFrame,colorFrame }),
    });
    const order = await response.json();
    // setOrders(orders.concat(order))
  }

  //edit order

  const editOrder = async (id, name,accurateAge, prescriptionDeadline, orderStatus) => {
    const url = `${HOST}/api/orders/updateorder/${id}`
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem('token')
      },

      body: JSON.stringify({ name,accurateAge, prescriptionDeadline, orderStatus }),
    });
    response.json();

    for (let index = 0; index < orders.length; index++) {

      if (orders[index]._id === id) {
        orders[index].name = name;
        orders[index].accurateAge = accurateAge;
        orders[index].prescriptionDeadline = prescriptionDeadline;
        orders[index].orderStatus = orderStatus;
        break;
      }
    }
    let newOrders = JSON.parse(JSON.stringify(orders))
    setOrders(newOrders);
  }

  //delte order

  const deleteOrder = async (id) => {
    const url = `${HOST}/api/orders/deleteorder/${id}`
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token":  localStorage.getItem('token')
      },
    });
    response.json();

    let order = orders.filter((n) => { return n._id !== id })
    setOrders(order)

  }


  //clear Order
  const clearOrder = async()=>{
    orders.splice(0,orders.length);
  }


  const [orders, setOrders] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  return (
    <OrderContext.Provider value={{ orders, addOrder, editOrder, deleteOrder, getOrder,clearOrder,totalPage }}>
      {props.children}
    </OrderContext.Provider>

  )
}

export default OrderState