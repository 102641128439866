import React, { useContext, useEffect } from 'react'
import faceContext from '../../Context/faceDetection/faceContext'
import framesContext from '../../Context/recommendedFrames/framesContext'
import progressContext from '../../Context/progressFlag/progressContext'
import userContext from '../../Context/users/userContext'
import { decisionMaker } from '../../Utilities/DecisionMaker/decisionMaker'
import { useState } from 'react'

const Processing = () => {

  const progresscontext = useContext(progressContext)
  const { progressFlag, updateProgressFlag } = progresscontext;

  const facecontext = useContext(faceContext)
  const { faces } = facecontext

  const framecontext = useContext(framesContext)
  const { prepareUI } = framecontext


  const { user, updateProfile } = useContext(userContext)



  const processResults = async () => {
    try {
      const decision = await decisionMaker(faces.avgShape, faces.faceColor, faces.hairColor, faces.hairStyle, faces.personality, faces.avgAge, faces.avgGender)
      if (await prepareUI(decision, user?.settings?.recommendationLimit)) {

        const todayDateString = new Date().toDateString();
        const newInteraction = user.stats?.scan?.[`${todayDateString}`]?.interaction + 1 || 1
        const stats = {
          scan: {
            [`${todayDateString}`]: { interaction: newInteraction }
          },
        }
  
        await updateProfile(null, null, null, null, stats)
        const newProgressFlag = { ...progressFlag, processing: false, resultDisplay: true };
        updateProgressFlag(newProgressFlag);
      }
      
    } catch (e) {
      console.log("An error Occured")
    }

  }

  useEffect(() => {
    processResults()
  }, [])



  return (
    <>
      <center style={{
        paddingTop: '15%'
      }}>
        <img src={require('../../assets/gifs/two_cirlces_loading.gif')} style={{
          width: "200px",
          height: '200px'
        }} alt="" />
      </center>

    </>
  )
}

export default Processing