import React, { useContext, useEffect, useRef, useState } from "react";
import framesContext from "../../../Context/recommendedFrames/framesContext";
import faceContext from "../../../Context/faceDetection/faceContext";
import "../../../assets/css/button.css";
import "../../../assets/css/mainStory/recommendation.css";
import progressContext from "../../../Context/progressFlag/progressContext";
import userContext from "../../../Context/users/userContext";
import { ReactComponent as LoadingSpinnerSmall } from '../../../assets/svgs/loading_spinner_small.svg';
import {
  makeSentencefromreasons,
} from "../../../Utilities/commonFunction";
import { ReactComponent as Logo } from "../../../assets/svgs/logoBlue.svg";
import { Accordion } from "react-bootstrap";
import ordersContext from "../../../Context/orders/ordersContext";
import drawShapeOverFace from "../../../Utilities/drawShapeOverFace";

const Recommendation = () => {
  const canvasRef = useRef(null);
  const btnSelectRef = useRef(null);

  const { progressFlag, updateProgressFlag, resetProgressFlag } =
    useContext(progressContext);
  const { frames, clearFrameState } = useContext(framesContext);
  const { user, updateProfile } = useContext(userContext);
  const { faces, clearFaceState, clearDetections } = useContext(faceContext);
  const { addOrder } = useContext(ordersContext)


  const [selectedFrame, setSelectedFrame] = useState(frames.frame[0][0]);
  const [selectedColor, setSelectedColor] = useState(frames.color[0][0]);

  const [selectedFrameIndex, setSelectedFrameIndex] = useState(0);
  const [selectedColorIndex, setSelectedColorIndex] = useState(0);

  const [tab, setTab] = useState({
    frame: true,
    color: false,
  });

  const [updating, setUpdating] = useState(false)

  const rescanHandler = () => {
    clearDetections();
    clearFrameState();

    const newProgressFlag = {
      ...progressFlag,
      isSelectedItem: false,
      resultDisplay: false,
      detection: true,
      rescan: true,
    };
    updateProgressFlag(newProgressFlag);
  };


  const createNewOrder = async () => {
    await addOrder(
      faces.resizedImg.toDataURL(), faces.avgShape,
      faces.PD, faces.avgGender, faces.avgAge,
      faces.faceColor, faces.eyeColor, faces.hairColor, faces.hairStyle, faces.personality, selectedFrame, selectedColor)
  }

  const updateStats = async () => {
    const todayDateString = new Date().toDateString();

    const newCompscans = user.stats?.scan?.[`${todayDateString}`]?.compscans + 1 || 1
    const newGenderScans = user.stats?.scan?.[`${todayDateString}`]?.genderscan?.[`${faces.avgGender}`] + 1 || 1
    const newAgeScans = user.stats?.scan?.[`${todayDateString}`]?.agescan?.[`${faces.avgAge > 55 ? "elder" : faces.avgAge < 18 ? 'young' : 'adult'}`] + 1 || 1
    const newChoosenFrame = user.stats?.choosenglasses?.[`${selectedFrame}`] + 1 || 1
    const newChoosenColor = user.stats?.choosencolors?.[`${selectedColor}`] + 1 || 1

    const recFrames = {}
    for (let i = 0; i < frames.frame.length; i++) {
      recFrames[frames.frame[i][0]] = user.stats?.recommenededframes?.[`${frames.frame[i][0]}`] + 1 || 1
    }

    const recColors = {}
    for (let i = 0; i < frames.color.length; i++) {
      recColors[frames.color[i][0]] = user.stats?.recommenededcolors?.[`${frames.color[i][0]}`] + 1 || 1
    }

    const stats = {
      scan: {
        [`${todayDateString}`]: {
          compscans: newCompscans,
          genderscan: { [`${faces.avgGender}`]: newGenderScans },
          agescan: { [`${faces.avgAge > 55 ? "elder" : faces.avgAge < 18 ? 'young' : 'adult'}`]: newAgeScans }
        }
      },
      choosenglasses: {
        [`${selectedFrame}`]: newChoosenFrame
      },
      choosencolors: {
        [`${selectedColor}`]: newChoosenColor
      },
      recommenededframes: recFrames,
      recommenededcolors: recColors
    }

    updateProfile(null, null, null, null, stats)
  }

  const selectFrameHandler = async () => {

    setUpdating(true)
    btnSelectRef.current.classList.add('d-none')

    await createNewOrder()
    await updateStats()

    const newProgressFlag = { ...progressFlag, isSelectedItem: true };
    updateProgressFlag(newProgressFlag);

    setUpdating(false)
    btnSelectRef.current.classList.remove('d-none')
  }

  const updateSelectedFrame = (f, i) => {
    setSelectedFrameIndex(i);
    setSelectedFrame(f);
    drawImage(f);
  };

  const updateSelectedColor = (c, i) => {
    setSelectedColor(c);
    setSelectedColorIndex(i);
    drawImage(selectedFrame, c);
  };

  useEffect(() => {
    if (canvasRef) {
      drawImage();
    }
  }, []);

  const drawImage = () => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; 
    img.src = faces.resizedImg.toDataURL();

    img.addEventListener(
      "load",
      function () {
        canvasRef.current.width = img.width;
        canvasRef.current.height = img.height;
        const totalWidth = img.width;
        const totalHeight = img.height;

        const ctxTarget = canvasRef.current.getContext("2d");

        ctxTarget.drawImage(img, 0, 0);
        drawShapeOverFace(ctxTarget, faces.avgShape,faces.landmarksPoint,totalWidth,totalHeight)
      },
      false
    );
  };

  const goBack = () => {
    resetProgressFlag();
    clearFaceState();
    clearFrameState();
  };

  return (
    <>
      <section className="sectionR">
        <div className="leftR">
          <Logo onClick={goBack} className="m-2 logoFD2" />

          <div className="personphotodivR">
            <canvas ref={canvasRef} className="img-fluid" alt="..." />
          </div>
        </div>

        <div className="rightR">
          <div className="tabR">
            <span className={`${tab.frame ? "fw-bold fs-5" : ""}`}>
              Recommendations
            </span>
          </div>


          <div className="framespreviewR">
            {frames.frame.map((element, index) => {
              return (
                <img
                  onClick={() => {
                    updateSelectedFrame(element[0], index);
                  }}
                  className={
                    selectedFrame === element[0] ? "borderbottomR" : ""
                  }
                  src={require(`../../../assets/new_glasses_2.0/${selectedColor}/${element[0]}.png`)}
                  alt=""
                />
              );
            })}
          </div>

          <div className="colorsviewR">
            <span>Select Colors</span>
            <div>
              {
                frames.color.map((element, index) => {
                  return (
                    <img
                      onClick={() => {
                        updateSelectedColor(element[0], index);
                      }}
                      className={
                        selectedColor === element[0] ? "borderGlowR" : ""
                      }
                      src={require(`../../../assets/textures/${element[0]}.png`)}
                      alt=""
                    />
                  )
                })
              }
            </div>
          </div>

          <div className="colorsviewR">
            <span>Your Face shape is {faces.avgShape}</span>
          </div>

          <div className="parentbtnselectR">
            <button disabled={progressFlag.isSelectedItem || selectedColor === null} ref={btnSelectRef} onClick={selectFrameHandler} className="btn btnselectR mt-3">
              Select Frame
            </button>
            {updating && <LoadingSpinnerSmall style={{ marginTop: '10px', width: "30px", height: "30px" }} />}
            
          </div>

          <span className="glassesdetailR mt-4 ps-3">
            These are {selectedFrame} glasses with {selectedColor} color.
          </span>

          <Accordion className="container mt-2">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionheaderR">
                How do the glasses match me?
              </Accordion.Header>
              <Accordion.Body className="accordionbodyR">
                These glasses are
                {frames.frame[selectedFrameIndex][2] > 8
                  ? " extremely recommended "
                  : frames.frame[selectedFrameIndex][2] > 6
                    ? " highly recommended "
                    : frames.frame[selectedFrameIndex][2] > 4
                      ? " recommended "
                      : frames.frame[selectedFrameIndex][2] > 2
                        ? " not very much recommended "
                        : " unlikely recommended "}
                to you because it is{" "}
                {frames.frame[selectedFrameIndex][1].length === 1
                  ? "only "
                  : ""}
                recommended on basis of{" "}
                {`${makeSentencefromreasons(
                  frames.frame[selectedFrameIndex][1]
                )}`}{" "}
                The color of your glasses are
                {frames.color[selectedColorIndex][2] > 8
                  ? " extremely recommended "
                  : frames.color[selectedColorIndex][2] > 6
                    ? " highly recommended "
                    : frames.color[selectedColorIndex][2] > 4
                      ? " recommended "
                      : frames.color[selectedColorIndex][2] > 2
                        ? " not very much recommended "
                        : " unlikely recommended "}
                because of{" "}
                {`${makeSentencefromreasons(
                  frames.color[selectedColorIndex][1]
                )}`}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion className="container mt-2">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordionheaderR">
                What are my face attributes detected and how they effect the recommendation?
              </Accordion.Header>
              <Accordion.Body className="accordionbodyR">
                Your detected face shape is {faces.avgShape} and skin tone is {faces.faceColor}. The personality you choose define the colors of glasses either to be prominent on your face or compliments it.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="parentbtnrescanR">
            <button onClick={rescanHandler} className="btnrescanR mt-5">
              Scan Again
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Recommendation;
