import React, { useState } from "react";
import UserContext from "./userContext";

const UserState = (props) => {
  const HOST = process.env.REACT_APP_BACKEND_URL;

  //create a new User
  const createUser = async (
    userName,
    shopName,
    shopAddress,
    email,
    password
  ) => {
    const url = `${HOST}/api/auth/createuser`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        name: userName,
        shopName: shopName,
        shopAddress: shopAddress,
        email: email,
        password: password,
      }),
    });
    const json = await response.json();
    return json;
  };

  //check for verification
  const verificationCheck = async () => {
    const url = `${HOST}/api/auth/checkverify`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    return json.success;
  };

  //verify the email
  const verifyEmail = async (id, token) => {
    const url = `${HOST}/api/auth/verifyuser/${id}/${token}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    return json;
  };

  //verify the user by his token
  const verifyToken = async () => {
    const url = `${HOST}/api/auth/getuser`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },
    });
    const json = await response.json();
    setUser(json.user);
    return json.success;
  };

  //send reset password link
  const sendResetLink = async (email) => {
    const url = `${HOST}/api/auth/reset`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });
    const json = await response.json();

    return json;
  };

  //check for reset password validity
  const resetValidURL = async (id, token) => {
    const url = `${HOST}/api/auth/reset/${id}/${token}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    return json;
  };

  //reset the password
  const resetPassword = async (id, token, password) => {
    const url = `${HOST}/api/auth/newpassword/${id}/${token}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ password: password }),
    });
    const json = await response.json();
    return json;
  };

  //resend verification link
  const resendVerificationLink = async (email) => {
    const url = `${HOST}/api/auth/resendlink`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    });
    const json = await response.json();
    return json;
  };

  //get user
  const getUser = async () => {
    try {
      const url = `${HOST}/api/auth/getuser`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "auth-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({ rememberMe: localStorage.getItem("remme") }),
      });
      const json = await response.json();
      return json;
    } catch (error) {
      // console.log(error)
      return "Error";
    }
  };

  //login user
  const loginUser = async (email, password, rememberMe) => {
    const url = `${HOST}/api/auth/login`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, rememberMe }),
    });
    const json = await response.json();
    return json;
  };

  //update profile

  const updateProfile = async (
    settings,
    name,
    shopName,
    shopAddress,
    stats
  ) => {
    const url = `${HOST}/api/auth/updateprofile`;
    let newStats = null;
    if (stats) {
      const date = new Date().toDateString();
      newStats = {
        ...user.stats,
        scan: {
          ...user.stats?.scan,
          [`${date}`]: {
            ...user.stats?.scan[`${date}`],
            ...stats?.scan[`${date}`],
            genderscan: {
              ...user.stats?.scan?.[`${date}`]?.genderscan,
              ...stats.scan?.[`${date}`]?.genderscan,
            },
            agescan: {
              ...user.stats?.scan?.[`${date}`]?.agescan,
              ...stats.scan?.[`${date}`]?.agescan,
            },
          },
        },
        choosenglasses: {
          ...user.stats?.choosenglasses,
          ...stats.choosenglasses,
        },
        choosencolors: {
          ...user.stats?.choosencolors,
          ...stats.choosencolors,
        },
        recommenededframes: {
          ...user.stats?.recommenededframes,
          ...stats.recommenededframes,
        },
        recommenededcolors: {
          ...user.stats?.recommenededcolors,
          ...stats.recommenededcolors,
        },
      };
    }

    // console.log({newStats})
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "auth-token": localStorage.getItem("token"),
      },

      body: JSON.stringify({
        settings: settings,
        name: name,
        shopName: shopName,
        shopAddress: shopAddress,
        stats: newStats,
      }),
    });
    const json = await response.json();
    if (newStats) user.stats = newStats;
    if (settings) user.settings = settings;
    if (shopName) user.shopName = shopName;
    if (shopAddress) user.shopAddress = shopAddress;

    return json;
  };

  const [user, setUser] = useState({});
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        createUser,
        verificationCheck,
        verifyEmail,
        verifyToken,
        sendResetLink,
        resetPassword,
        resetValidURL,
        resendVerificationLink,
        getUser,
        loginUser,
        updateProfile,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
