import React, { useState } from "react";
import FeedbackContext from "./FeedbackContext";

const FeedbackState = (props) => {

  const HOST = process.env.REACT_APP_BACKEND_URL;
  
  const saveFeedback = async (type, ratings, email, experience) => {
    const url = `${HOST}/api/feedback/savefeedback`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({ type, ratings, email, experience }),
    });
    const review = await response.json();
    setfeedback(review.message);
    return review.success;
  };

  const [feedback, setfeedback] = useState(null);

  return (
    <FeedbackContext.Provider value={{ feedback, saveFeedback }}>
      {props.children}
    </FeedbackContext.Provider>
  );
};
export default FeedbackState;
