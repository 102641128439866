const drawShape = (totalWidth, totalHeight, points, ctx, faceLandmarks) => {
  ctx.beginPath();
  points.forEach((point, index) => {
    if (index === 0)
      ctx.moveTo(
        faceLandmarks[point].x * totalWidth,
        faceLandmarks[point].y * totalHeight
      );

    ctx.lineTo(
      faceLandmarks[point].x * totalWidth,
      faceLandmarks[point].y * totalHeight
    );
  });
  ctx.stroke();
};

const drawOvalShape = (ctx, faceLandmarks, totalWidth, totalHeight) => {
    const points=[152,378,288,323,454,356,389,332,338,67,162,127,234,132,58,136,176,152]
    drawShape(totalWidth,totalHeight,points,ctx,faceLandmarks)
};
const drawShapeOverFace = (
  ctx,
  shape,
  faceLandmarks,
  totalWidth,
  totalHeight
) => {
    // Set up a gradient for a shiny effect
    const gradient = ctx.createLinearGradient(0, 0, totalWidth, 0);
    gradient.addColorStop(0, "white");
    gradient.addColorStop(0.5, "lightgray");
    gradient.addColorStop(1, "white");
    ctx.strokeStyle = gradient;
  
    // Add shadow for depth
    ctx.shadowColor = "rgba(0, 0, 0, 0.5)";
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = 5;
    ctx.shadowOffsetY = 5;
  if (shape === "oval") {
    ctx = drawOvalShape(ctx, faceLandmarks, totalWidth, totalHeight);
  }
  return ctx;
};

export default drawShapeOverFace;
